<template>
<nav>
    <Notificaton/>
    <v-app-bar app dense class="headercolor">
      <v-app-bar-nav-icon
        v-if="nofreeaccess"
        class="whitercolor topzindex alerts-border"
        @click="drawer = !drawer"
        :title="lang.Projects"
      ></v-app-bar-nav-icon>
      <v-toolbar-title v-if="checkcam360" class="white--text logolink">
        <span class="font-weight-bold shado"
          ><img class="logo" @click="setrouter('/dashboard-360')" :src="logo"
        /></span>
      </v-toolbar-title>
      <v-toolbar-title v-if="!checkcam360" class="white--text logolink">
        <span class="font-weight-bold shado"
          ><img class="logo" @click="setrouter('/dashboard')" :src="logo"
        /></span>
      </v-toolbar-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="projectname white--text"
            >{{ pname }}
          </span>
        </template>
        <span>{{ currentProject.company }}</span>
      </v-tooltip>

      <v-select 
        
        :items="camers"
        item-text="camName"
        item-value="iindex"
        :label="lang.SelectCamera"
        :class="`light small camdropdown ${camers.length > 1 ? 'show':'hide'}`"
        v-model="camid"
        small
        @change="selectCamera(camid)"
        solo
        outlined
        color="white"
      ></v-select>

      <v-spacer></v-spacer>
      <!-- dropdown menu -->
     <div :class="`mobilemenu ${showmobilemenu ? 'showmenu' : 'hidemenu'}`">

       <!-- <v-menu offset-y >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :color="
              '/dashboard/ai-data' == activelink ? activecolor : 'white'
            "
            small
            v-bind="attrs"
            v-on="on"
            :class="`${ ai ? 'show':'hide'}`"
          >
            <v-icon left>mdi-crosshairs-gps</v-icon>
            <span> {{lang.AIData}} </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('/dashboard/ai-data')">
            <v-list-item-title> {{lang.AIDataAnalysis}} </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="nofreeaccess"
            @click="setrouter('/dashboard/settings/ai-data-schedule-report')"
          >
            <v-list-item-title> {{lang.PpeReports}} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
     <v-btn v-if="!openAccessFeature"
        text
        :class="`navmenu_item ${ aqi ? 'show':'hide'}`"
        small
         :color="'/dashboard/aqi-data' == activelink ? activecolor : 'white'"
        @click="setrouter('/dashboard/aqi-data')"
      >
        <v-icon left>mdi-air-purifier</v-icon>
        <span> {{lang.Aqi}}  </span>
      </v-btn>
        <v-btn
        text
        :class="`navmenu_item ${ people_count ? 'show':'hide'}`"
        small
         :color="'/dashboard/people-detection' == activelink ? activecolor : 'white'"
        @click="setrouter('/dashboard/people-detection')"
      >
        <v-icon left>mdi-account-switch</v-icon>
        <span> {{lang.PeopleDetection}} </span>
      </v-btn>
      <v-btn
        text
        color="white"
        :class="`navmenu_item ${archive ? 'show':'hide'} aarchive`"
        small
     
        @click="archiveOpen"
      >
        <v-icon left>mdi-calendar-range</v-icon>
        <span> {{lang.Archive}} </span>
      </v-btn>
          <!-- timelapse mobile menus -->
          <v-btn
        text
        color="white"
        :class="`navmenu_item ${archive ? 'show':'hide'} mobileonly`"
        small
     
         @click="setrouter('/dashboard/timelapse')"
      >
        <v-icon left>mdi-calendar-range</v-icon>
        <span>  {{lang.WatchTimelapse}}</span>
      </v-btn>

      <v-btn
        text
        color="white"
        :class="`navmenu_item ${archive ? 'show':'hide'} mobileonly`"
        small
     
        @click="conditionsetrouter('/dashboard/settings/create-timelapse')"
      >
        <v-icon left>mdi-calendar-range</v-icon>
        <span> {{lang.CreateTimelapse}} </span>
      </v-btn>

       
          
           <!-- timelapse mobile menus end -->


      <v-btn
        text
        :color="'/dashboard/live' == activelink ? activecolor : 'white'"
        :class="`navmenu_item ${current_cam.cctv > 0 && showurls && nofreeaccess ? 'show':'hide'}`"
        small
        @click="setrouter('/dashboard/live')"
       
      >
        <v-icon left>mdi-video</v-icon>
        <span> {{lang.Live}} </span>
      </v-btn>

      <v-menu offset-y :class="`${current_cam.cctv > 0 && showurls && nofreeaccess ? 'show':'hide'} desktop`"
        v-if="nofreeaccess && !people_count"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :color="
              '/dashboard/timelapse' == activelink ? activecolor : 'white'
            "
            class="desktop"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-play-circle</v-icon>
            <span>{{lang.Timelapse}}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('/dashboard/timelapse')">
            <v-list-item-title> {{lang.WatchTimelapse}}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="nofreeaccess"
            @click="conditionsetrouter('/dashboard/settings/create-timelapse')"
          >
            <v-list-item-title> {{lang.CreateTimelapse}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        text
        :color="'/dashboard/timelapse' == activelink ? activecolor : 'white'"
        :class="`navmenu_item ${people_count ? 'hide':'show'}`"
        small
        @click="setrouter('/dashboard/timelapse')"
        v-if="!nofreeaccess"
      >
        <v-icon left>mdi-play-circle</v-icon>
        <span>{{lang.Timelapse}} </span>
      </v-btn>

      <!-- <v-btn
        text
        :color="'/dashboard/compare' == activelink ? activecolor : 'white'"
        class="navmenu_item"
        small
        @click="setrouter('/dashboard/compare')"
        v-if="showurls && nofreeaccess"
      >
        <v-icon left>mdi-compare</v-icon>
        <span> {{lang.Compare}}  </span>
      </v-btn> -->

      <!-- compare submenus -->
       
         <v-menu offset-y v-if="showurls && nofreeaccess">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
           :color="'/dashboard/compare' == activelink ? activecolor : 'white'"
            small
            :class="`navmenu_item`"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-compare </v-icon>
            <span>  {{lang.Compare}}  </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('/dashboard/compare')">
            <v-list-item-title> Image Compare </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/compare-bim-overlay')">
            <v-list-item-title> BIM Compare  </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


       <v-menu offset-y  v-if="current_cam.vehicle_ai == 1 || current_cam.ppe == 1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
           :color="'/dashboard/ai' == activelink ? activecolor : 'white'"
            small
            :class="`navmenu_item`"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-head-lightbulb</v-icon>
            <span> AI </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="checkUserAccess('/dashboard/ai')"  v-if="current_cam.vehicle_ai == 1">
            <v-list-item-title> Vehicle & Man-power Detection </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item @click="checkUserAccess('/dashboard/ai-reports')">
            <v-list-item-title> AI Reports </v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="checkUserAccess('/dashboard/ppe-detection')"  v-if="current_cam.ppe == 1">
            <v-list-item-title> PPE Detection </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
            <!-- <v-btn text class="navmenu_item" small :color="'/dashboard/ai' == activelink ? activecolor : 'white'"
              @click="checkUserAccess('/dashboard/ai')">
     
        <v-icon left>mdi-head-lightbulb</v-icon>
        <span>AI</span>
      </v-btn> -->
      <v-btn
        text
        :color="
          '/dashboard/mobile-upload' == activelink ? activecolor : 'white'
        "
        :class="`navmenu_item ${people_count ? 'hide':'show'}`"
        small
        @click="setrouter('/dashboard/mobile-upload')"
        v-if="showurls && !openAccessFeature"
      >
        <v-icon left>mdi-cellphone </v-icon>
        <span>  {{lang.MobileUpload}} </span>
      </v-btn>
      
      <v-menu offset-y v-if="showurls && nofreeaccess && !openAccessFeature" class="desktop">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="
              '/dashboard/planning/edit-task' == activelink
                ? activecolor
                : 'white'
            "
            small
            :class="`navmenu_item ${people_count ? 'hide':'show'} desktop`"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-floor-plan </v-icon>
            <span>  {{lang.Planning}}  </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('/dashboard/planning/edit-task')">
            <v-list-item-title>  {{lang.EditData }} </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/planning/compare-events')">
            <v-list-item-title> {{lang.CompareEvents }}  </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/planning/event-chart')">
            <v-list-item-title>  {{lang.EventChart }}  </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="setrouter('/dashboard/planning/event-timelapse')"
          >
            <v-list-item-title>  {{lang.EventTimelapse }}  </v-list-item-title>
          </v-list-item>
        
        </v-list>
      </v-menu>

      <v-menu :close-on-content-click="false" offset-y v-if="showurls && nofreeaccess && !people_count && !openAccessFeature"
      :class="`${showurls && nofreeaccess ? 'show':'hide'}`">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="
              '/dashboard/reports/instant-report' == activelink
                ? activecolor
                : 'white'
            "
            small
            class="navmenu_item  desktop"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-file-document-multiple</v-icon>
            <span> {{lang.Report }}  </span>
          </v-btn>
        </template>
            <v-list>
              
             <v-list-group no-action v-for='report_item in report_items' :key="report_item.title">
                    <v-list-item slot='activator' @click="setrouter(report_item.route)" :to="report_item.route">
                       
                        <v-list-item-title>{{ report_item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for='sub in report_item.subitems' :key="sub.title" @click="setrouter(report_item.route)" :to="sub.route">
                        <v-list-item-title>{{ sub.title }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
          </v-list>
      <!--  <v-list>
          <v-list-item @click="setrouter('/dashboard/reports/cart-report')">
            <v-list-item-title> Create Detailed Report </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/reports/instant-report')">
            <v-list-item-title> Instant Report </v-list-item-title>
             
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/reports/schedule-report')">
            <v-list-item-title> Schedule Report </v-list-item-title>
          </v-list-item>
          
           <v-list-item @click="setrouter('/dashboard/reports/report-demo')">
            <v-list-item-title> Report Demo </v-list-item-title>
          </v-list-item> 
        </v-list>-->
      </v-menu>
       <v-btn
        text
        :color="'/dashboard/more/favorite-list' == activelink ? activecolor : 'white'"
        :class="`navmenu_item ${ people_count ? 'show':'hide'}`"
        small
        @click="setrouter('/dashboard/more/favorite-list')"
       
      >
        <v-icon left>mdi-clipboard-check</v-icon>
        <span> {{ lang.FavoriteList }} </span>
      </v-btn>
       <v-btn
        text
        :color="'/dashboard/splitscreen' == activelink ? activecolor : 'white'"
        :class="`navmenu_item ${ people_count ? 'show':'hide'}`"
        small
        @click="setrouter('/dashboard/splitscreen')"
       
      >
        <v-icon left>mdi-view-split-vertical</v-icon>
        <span> {{ lang.SplitScreen }} </span>
      </v-btn>
      <v-menu offset-y :class="`${showurls && nofreeaccess > 1 && people_count ? 'show':'hide'}`" v-if="!openAccessFeature">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="
              '/dashboard/more/favorite-list' == activelink
                ? activecolor
                : 'white'
            "
            small
            :class="`navmenu_item ${!people_count ? 'show':'hide'} desktop`"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-dots-vertical </v-icon>
            <span> {{ lang.More }} </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('/dashboard/more/favorite-list')">
            <v-list-item-title> {{ lang.FavoriteList }}  </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="setrouter('/dashboard/more/event-timeline')"
            v-if="!checkcam360 && !people_count"
          >
            <v-list-item-title> {{ lang.EventTimeline }} </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setrouter('/dashboard/splitscreen')">
            <v-list-item-title> {{ lang.SplitScreen }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
       <!-- mobilemenu items -->

         <v-btn v-if="!openAccessFeature"
        text
        :color="
          '/dashboard/splitscreen' == activelink ? activecolor : 'white'
        "
        class="navmenu_item mobilebmenussitemw"
        small
        @click="setrouter('/dashboard/splitscreen')"
       
      >
        <v-icon left>mdi-compare</v-icon>
        <span> {{ lang.SplitScreen }} </span>
      </v-btn>

            
             <v-btn v-if="!openAccessFeature"
        text
        :color="
          '/dashboard/more/favorite-list' == activelink ? activecolor : 'white'
        "
        class="navmenu_item mobilebmenussitemw"
        small
        @click="setrouter('/dashboard/more/favorite-list')"
       
      >
        <v-icon left>mdi-star-box-multiple</v-icon>
        <span> {{ lang.FavoriteList }} </span>
      </v-btn>
      <v-btn v-if="!openAccessFeature"
        text
        :color="
          '/dashboard/more/event-timeline' == activelink ? activecolor : 'white'
        "
        class="navmenu_item mobilebmenussitemw"
        small
        @click="setrouter('/dashboard/more/event-timeline')"
        
      >
        <v-icon left>mdi-calendar-clock</v-icon>
        <span> {{ lang.EventTimeline }} </span>
      </v-btn>

         <!-- mobile menus end -->
      <v-btn
        text
        :color="
          '/dashboard/more/favorite-list' == activelink ? activecolor : 'white'
        "
        class="navmenu_item"
        small
        @click="setrouter('/dashboard/more/favorite-list')"
        v-if="!nofreeaccess"
      >
        <v-icon left>mdi-compare</v-icon>
        <span> {{ lang.FavoriteList }} </span>
      </v-btn>
      <v-btn
        text
        :color="
          '/dashboard/more/event-timeline' == activelink ? activecolor : 'white'
        "
        class="navmenu_item"
        small
        @click="setrouter('/dashboard/more/event-timeline')"
        v-if="!nofreeaccess"
      >
        <v-icon left>mdi-compare</v-icon>
        <span> {{ lang.EventTimeline }} </span>
      </v-btn>

      <v-btn
        text
        title="To Home"
        color="white"
        :class="`navmenu_item ${'/dashboard' !== activelink  && '/dashboard-360' !== activelink ? 'show': 'hide'}`"
        small
        @click="setrouter('/dashboard')"
       
      >
        <v-icon left>mdi-undo-variant</v-icon>
        <span>  {{ lang.Back }} </span>
      </v-btn>
      <!-- <v-btn text title="To Home" :color="'/dashboard-360' == activelink ? activecolor: 'white'" class="navmenu_item" small @click="setrouter('/dashboard-360')" v-if="'/dashboard' !== activelink && '/dashboard-360' !== activelink && checkcam360">
       
            <v-icon left>mdi-undo-variant</v-icon>
            <span> Back </span>
        </v-btn> -->
  </div>
    <v-menu offset-y :class="`${current_cam.cctv > 0 && showurls && nofreeaccess ? 'show':'hide'} tabonly`"
        v-if="nofreeaccess && !people_count"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :color="
              '/dashboard/timelapse' == activelink ? activecolor : 'white'
            "
            small
           
            v-bind="attrs"
            v-on="on"
            class="tabonly"
          >
            <v-icon left>mdi-play-circle</v-icon>
            <span>{{lang.Timelapse}}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="setrouter('/dashboard/timelapse')">
            <v-list-item-title> {{lang.WatchTimelapse}}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="nofreeaccess"
            @click="conditionsetrouter('/dashboard/settings/create-timelapse')"
          >
            <v-list-item-title> {{lang.CreateTimelapse}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        <v-btn
        text
        color="white"
        :class="`navmenu_item ${archive ? 'show':'hide'}  tabonly`"
        small
     
        @click="archiveOpen"
      >
        <v-icon left>mdi-calendar-range</v-icon>
        <span> {{lang.Archive}} </span>
      </v-btn>
          <v-btn class="secondcolor black-text mobileview" @click="mobilemenus" :title="lang.Menu" dark v-bind="size" ><span>Menu</span><v-icon dark right> mdi-arrow-down-drop-circle </v-icon>
          </v-btn>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        style="z-index: 12"
        
      >
        <template v-slot:activator="{ on }">
          <v-btn class="secondcolor black-text"  dark v-bind="size" v-on="on" ><span>{{ lang.Options }} </span><v-icon dark right> mdi-cog-outline </v-icon>
          </v-btn>
        </template>

        <v-card class="mysettings">
          <v-list>
            <v-list-item>
              <v-list-item-avatar color="amber accent-3">
                <span class="white--text headline"
                  >{{ userdata.firstname[0] }}{{ userdata.lastname[0] }}</span
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ userdata.firstname }}
                  {{ userdata.lastname }}</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  userdata.user_code == 2
                    ? lang.SuperAdmin
                    : userdata.user_code == 1
                    ? lang.User
                    : lang.Admin
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="!openAccessFeature">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#000"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="logout()"
                    >
                      mdi-logout
                    </v-icon>
                  </template>
                  <span>{{lang.Logout}} </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          
         
          <v-list 
              class="mx-auto"
              max-width="300"
              max-height="500"
              dense
              >
          
            <v-list-item-group color="amber accent-3">
              <v-list-item v-if="!openAccessFeature"
                :color="
                  '/dashboard/settings/change-password' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/change-password')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-lock-reset</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{lang.UpdatePassword}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="useraccesssuperadmin"
                :color="
                  '/dashboard/settings/profile' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/profile')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.Profile }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="useraccess &&  nofreeaccess"
                :color="
                  '/dashboard/settings/adduser' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/adduser')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.AddUser }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="nofreeaccess && !openAccessFeature"
                :color="
                  '/dashboard/settings/manage-user' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/manage-user')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{lang.Users}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item
                v-if="useraccesssuperadmin"
                :color="
                  '/dashboard/settings/user-logs' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/user-logs')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-target-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lang.UserLogs}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="useraccess"
                :color="
                  '/dashboard/settings/schedule-email' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/schedule-email')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-email-sync</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{lang.EmailTrigger}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="useraccess && nofreeaccess"
                :color="
                  '/dashboard/settings/camera-notifications' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/camera-notifications')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-bell-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.CameraOffalert}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            
              <!-- <v-list-item
                v-if="useraccess && nofreeaccess && current_cam.ppe == 1"
                :color="
                  '/dashboard/settings/ai-data-schedule-report' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/ai-data-schedule-report')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-chart-pie</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> PPE Schedule Report </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-list-item
                v-if="useraccess && nofreeaccess"
                :color="
                  '/dashboard/settings/camerainfo' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/camerainfo')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cctv</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.CameraStatus}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              

              <v-list-item
                v-if="useraccess && nofreeaccess"
                :color="
                  '/dashboard/settings/camera/Cam_Name_and_Tag' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="
                  setrouter('/dashboard/settings/camera/Cam_Name_and_Tag')
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-camera</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.CameraNameAndTag}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="useraccess && nofreeaccess" :color="
                '/dashboard/settings/open-access-url' == activelink
                  ? activecolor
                  : 'grey darken-3'
              " @click="
  setrouter('/dashboard/settings/open-access-url')
              ">
                <v-list-item-icon>
                  <v-icon>mdi-link-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{ lang.createOpenAccessLinkNavButton }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-list-item
                v-if="nofreeaccess"
                :color="
                  '/dashboard/settings/create-timelapse' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="conditionsetrouter('/dashboard/settings/create-timelapse')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{lang.CreateCustomTimelapse}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-list-item
                v-if="billingaccess && nofreeaccess && zoho_id && !openAccessFeature"
                :color="
                  '/dashboard/settings/billing' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/billing')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-list-status</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.Billing}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-list-item
                v-if="useraccess && current_cam.bim == 1"
                :color="
                  '/dashboard/settings/bim_rendering' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/bim_rendering')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-image-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.ManageBIMRenderings}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-list-item
               v-if="useraccesssuperadmin"
                :color="
                  '/dashboard/settings/auto-desk-forge-view' == activelink ? activecolor : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/auto-desk-forge-view')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cube-scan</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> BIM Model </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="useraccess && nofreeaccess"
                :color="
                  procore_url == activelink ? activecolor : 'grey darken-3'
                "
                @click="setrouter(procore_url)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-toy-brick-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.Integration}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-list-item  @click="setrouter('/dashboard/settings/procore_connection')"  :color="'/dashboard/settings/procore_connection' == activelink ? activecolor: 'grey darken-3'">
          <v-list-item-icon>
            <v-icon>mdi-connection</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title > Connect With Procore </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

          <v-list-item  @click="setrouter('/dashboard/settings/procore_update')"  :color="'/dashboard/settings/procore_update' == activelink ? activecolor: 'grey darken-3'">
          <v-list-item-icon>
            <v-icon>mdi-connection</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >  Procore Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
               <v-list-item @click="setrouter('/dashboard/settings/security/two-factor-auth-setup')" v-if="!openAccessFeature">
                <v-list-item-icon>
                  <v-icon>mdi-security</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.TwoFactorAuthentication}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setrouter('/dashboard/settings/unsubscribe-email-notification')" v-if="!openAccessFeature">
                <v-list-item-icon>
                  <v-icon>mdi-email-off</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Unsubscribe Email Notification</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
               <v-list-item :href ="'https://www.opticvyu.com/opticvyu-demo'" target="_blank">
                <v-list-item-icon>
                  <v-icon>mdi-video</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.DemoVideos}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="support_dialog">
                <v-list-item-icon>
                  <v-icon>mdi-face-agent</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.Support}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="useraccess && nofreeaccess && camconfig_access"
                :color="
                  '/dashboard/settings/camera/configuration' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/camera/configuration')"
                style="background-color: #e3e1e1;"
              >
                <v-list-item-icon>
                  <v-icon>mdi-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>  {{lang.CameraConfiguration}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="useraccess && nofreeaccess && camconfig_access"
                :color="
                  '/dashboard/settings/check_camera/installation' == activelink
                    ? activecolor
                    : 'grey darken-3'
                "
                @click="setrouter('/dashboard/settings/check_camera/installation')"
                style="background-color: #e3e1e1;"
              >
                <v-list-item-icon>
                  <v-icon>mdi-check-all</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{lang.ProjectInfo}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <!-- <v-list-item
                v-if="useraccess && ecamaccess"
                 :color="
                   '/dashboard/settings/ecam-setup' == activelink
                     ? activecolor
                     : 'grey darken-3'
                 "
                style="background-color: #e3e1e1;"
            @click="setrouter('/dashboard/settings/ecam-setup')"
            >
            <v-list-item-icon>
              <v-icon>mdi-sticker-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Setup New Project/Camera</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item v-if="hasCRMAccess" :color="'/dashboard/settings/crm' == activelink
              ? activecolor
              : 'grey darken-3'
            " 
                style="background-color: #e3e1e1;"
                @click="
                  setrouter('/dashboard/settings/crm')
                  ">
                  <v-list-item-icon>
                    <v-icon>mdi-ticket-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ lang.CRM }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              <v-list-item v-if="hasDailyReportAccess" :color="'/dashboard/settings/daily-report' == activelink
                  ? activecolor
                  : 'grey darken-3'
                " 
                style="background-color: #e3e1e1;"
                @click="
                  setrouter('/dashboard/settings/daily-report')
                  ">
                  <v-list-item-icon>
                    <v-icon>mdi-file-document</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ lang.DailyReport }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

            </v-list-item-group>
          </v-list>
           <Languages/>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!-- <v-app-bar-nav-icon
        v-if="nofreeaccess"
        class="whitercolor newproj_exp"
        @click="drawer = !drawer"
        :title="lang.Projects"
      ></v-app-bar-nav-icon> -->
    <!-- <v-navigation-drawer v-model="drawer" app temporary width="300">
        <v-list-item @change="setrouter('/dashboard/settings/global-dashboard')">
            <template>
                <v-list-item-content>
                    <v-list-item-title> Interactive Dashboard </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon>mdi-earth</v-icon>
                </v-list-item-icon>
            </template>
        </v-list-item>
        <v-text-field v-model="searchtext" autocomplete="off"  label="Seach Projects" outlined clearable color="success" :loading='searchloader' @keydown="filterObjects"></v-text-field>

        <v-list>
            <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i" active-class="actiaveitem" color="amber accent-3" @change="selectConstruction(item)">
                    <template>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.project_name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-arrow-right-bold-circle</v-icon>
                        </v-list-item-icon>
                    </template>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        
    </v-navigation-drawer> -->
    <div :class="`popupnavebar ${fullpopup ? 'fullpopup' : '' }`" v-if="activelink == '/dashboard' && drawer">
      <v-row>
        <v-col cols="12"
          
         class="pt-0 pb-0">
           <span class="font-weight-bold shado logopopup "
           :class="currentProject.channel_partner"
          ><img class="logo" :src="logo"
        /></span> 
        <v-row class="searchBarRow">
          <input type="text"
          class="searchboxfull"
           v-model="searchtext"
          placeholder="Search Projects"
          @keyup="filterObjects"
            @keydown="filterObjects" 
            >
            <v-icon class="clier" @click="clearSearch()"
              >mdi-window-close</v-icon
            >
         
          <v-btn class="mr-3 slideshow" color="mylightbutton" @click="setrouter('/dashboard/slide-show')">
            <span class="desktop">{{ lang.SlideShow }}</span>
            <v-icon right>mdi-play-box-outline</v-icon>
          </v-btn>
          <v-btn
            class="mr-3 slideshow"
            color="mylightbutton"
            @click="setrouter('/dashboard/settings/global-dashboard')" v-if="!openAccessFeature"
          >
           <span class="desktop">{{ lang.MapView }}</span>
            <v-icon right>mdi-map-marker-radius</v-icon>
          </v-btn>
          <v-select
          :items="projectCity"
          v-model="selectedCity"
          label="City"
          solo dense clearable
          class="mr-3 citySelect"
          @change="filterProjects"
          :menu-props="{ contentClass: 'selectScroll'}"
        ></v-select>
        <v-select
          :items="projectStatus"
          v-model="selectedProjectStatus"
          label="Project Status"
          solo dense clearable
          class="mr-3 citySelect"
          @change="filterProjects"
          :menu-props="{ contentClass: 'selectScroll'}"
        ></v-select>
        </v-row>
        </v-col>
        <v-btn
          fab
          x-small
          color="#fff"
          class="closeDrower"
          @click="closeDrower"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <v-col
          v-for="(item, index) in popupProjectList"
          :key="item.id"
          :class="`projectitem ${
            item.id == currentProject.id ? 'selected' : ''
          }`"
           cols="12"
           xs="12"
           sm="6"
           md="6"
           lg="3"
           xl="3"
           @mouseover="getCurrentProjectCams(item.id)"
        >
            <div class="hovercms">

               <v-progress-linear
            color="#ffc400"
            indeterminate
            rounded
            height="6"
            v-if="camerspopup.length == 0"
          ></v-progress-linear>
                
               <span v-if="camerspopup.length > 0">
                <span v-for=" (cc, cindex ) in camerspopup.slice(0, 5)" class="popcamitem" :key="cc.camera_id">
                  <v-icon  :title="`CAM `+ (cindex+1)" dark @click="selectConstructionwithCame(item, cc.camera_id, index, cindex, 'single')"> mdi-camera-image </v-icon>
               </span>
               <span v-if="camerspopup.length > 1" class="popcamitem" style="color:#000;position:relative;top:0px; font-weight:bold">
                <span @click="selectConstructionwithCame(item, '', index, 0, 'all')"> All </span>
               </span>
             </span>
                 
            </div>
          <div :class="`proimagebox ${item.const_camera == 1 && user_cont_camera_aceess ? 'enable' : 'disable'}`" @click="selectConstruction(item,index)"   >
            <img
              :src="
                item.thumbnail_name == 'null'
                  ? project_image
                  : item.thumbnail_name
              "
            
              class="project_thumbnail lighten-2"
            />
           
            <div class="menuprojectname">
              {{ item.project_name }}
            </div>
          </div>
          <div class="menufooter">
             <span  title="Construction Camera">
            <v-btn
              class="mx-2"
              @click="selectConstruction(item)"
              fab
              x-small
              color="#fff"
              :title="lang.ConstructionCamera"
              :disabled="
                item.const_camera == 1 && user_cont_camera_aceess ? false : true
              "
            >
              <v-icon dark> mdi-cctv </v-icon>
            </v-btn>
            </span>
           <span  :title="lang.InteriorMonitoring">
            <v-btn
              class="mx-2"
              @click="selectInterior(item)"
              fab
              x-small
              color="#fff"
              
              :disabled="
                item.interior == 1 && user_interior_access ? false : true
              "
            >
              <v-icon dark> mdi-axis-z-rotate-clockwise </v-icon>
            </v-btn>
            </span>
            <span  :title="lang.MaterialTracking">
            <v-btn
              class="mx-2"
              @click="selectMaterial(item)"
              fab
              x-small
              color="#fff"
              
              :disabled="
                item.material == 1 && user_material_access ? false : true
              "
            >
              <v-icon dark> mdi-dump-truck </v-icon>
            </v-btn>
          </span>
            <!-- <span  :title="lang.DroneMonitoring">
              <v-btn
              class="mx-2"
              @click="selectDrone(item)"
              fab
              x-small
              color="#fff"
             
              :disabled="item.drone == 1 && user_drone_access ? false : true"
            >
              <v-icon dark> mdi-quadcopter </v-icon>
            </v-btn>
            </span> -->
            <span title="Construction Drone">
                            <v-btn class="mx-2" @click="selectLinear(item)" fab x-small color="#fff" title="Construction Drone" :disabled="item.linear_monitoring == 1 && user_linear_access ? false : true">
                                <v-icon dark>
                                    mdi-quadcopter
                                </v-icon>
                            </v-btn>
                            </span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      v-if="activelink !== '/dashboard'"
      app
      temporary
      width:300
    >
      <v-list-item @change="setrouter('/dashboard/settings/global-dashboard')">
            <template>
                <v-list-item-content>
                    <v-list-item-title> {{lang.InteractiveDashboard}} </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon>mdi-earth</v-icon>
                </v-list-item-icon>
            </template>
        </v-list-item>
        <v-text-field v-model="searchtext"  class="searchProject searchbox" autocomplete="off"  :label="lang.SearchProjects" outlined  color="success" :loading='searchloader' @keyup="filterObjects"  @keydown="filterObjects"></v-text-field>
         <!-- <v-icon class="clier" @click="clearSearch()">mdi-window-close</v-icon> -->
        <v-list  v-for="item in popupProjectList"
        :key="item.project_name"
        style="cursor: pointer;"
         >
      <v-list-group v-if="item.interior == 1 || item.material == 1 || item.drone == 1 || item.linear_monitoring == 1" no-action  color="#f8bb03" style="cursor: pointer;">
        <template v-slot:activator >
          <v-list-item-content>
            <v-list-item-title v-text="item.project_name"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item  v-bind:class="[isActive ? activeClass : '']"  @change="selectConstruction(item)"
          v-if="item.const_camera == 1 && user_cont_camera_aceess"
        >
          <v-list-item-content >
            <v-list-item-title :text="lang.ConstructionCamera"> {{lang.ConstructionCamera}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  active-class="actiaveitem"  v-if="item.interior == 1 && user_interior_access" @change="selectInterior(item)">
          <v-list-item-content>
            <v-list-item-title :text="lang.InteriorsMonitoring "> {{ lang.InteriorMonitoring }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           <!-- <v-list-item  active-class="actiaveitem" v-if="item.drone == 1 && user_drone_access"  @change="selectDrone(item)">
          <v-list-item-content>
            <v-list-item-title :text="lang.DroneMonitoring"> {{ lang.DroneMonitoring}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item  active-class="actiaveitem"  v-if="item.material == 1 && user_material_access" @change="selectMaterial(item)">
          <v-list-item-content>
            <v-list-item-title :text="lang.MaterialTracking "> {{ lang.MaterialTracking }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  active-class="actiaveitem"  v-if="item.linear_monitoring == 1 && user_linear_access" @change="selectLinear(item)">
          <v-list-item-content>
            <v-list-item-title text="Construction Drone"> Construction Drone </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>
      <v-list-item  active-class="actiaveitem"  v-if="item.interior == 0 && item.material == 0 && item.drone == 0 && item.const_camera == 1 && item.linear_monitoring == 0 && user_cont_camera_aceess"  @change="selectConstruction(item)"> 
       <template >
          <v-list-item-content>
            <v-list-item-title v-text="item.project_name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>  
    </v-list>
    </v-navigation-drawer>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      color="amber accent-3"
      v-model="support_popup"
    >
      <template>
        <v-card>
          <v-toolbar color="amber accent-3" dark> {{lang.ContactSupport}} </v-toolbar>

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitquery"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="support_subject"
                    :label="lang.Subject"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="supportmsg"
                    :label="lang.Message"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!valid"
                    color="mylightbutton"
                  >
                    {{lang.send}}
                    <v-progress-circular
                      v-if="sup_send"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn @click="support_popup = false" color="mydarkbutton">
                      {{lang.Cancel}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
  </nav>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@9"></script>
<script>
import axios from "axios";
import logo from "../../assets/ov.png";
import rawadhalogo from "../../assets/rawadh.png";
import lisuallogo from "../../assets/lisual.png";
import tatalogo from "../../assets/tatasmall.png";
import lntlogo from "../../assets/lnt.png";
import project_image from "../../assets/OpticVyu.jpg";

import smlogo from "../../assets/sm.png";
import lodhalogo from "../../assets/lodha.png";
import adanilogo from "../../assets/Adani_logo.png";
import globaltimelapselogo from "../../assets/globaltimelapse.png"; 
import equalbaselogo from "../../assets/equalbasedev_logo.jpeg";
import tbox from "../../assets/Tbox-logo-white-nowords.jpg"; 
import protbox from "../../assets/Tbox-logo-white-nowords.jpg"; 
import headerNotificaton from './headerNotification';
import Languages from "./LanguageSelecter";
import VueSweetalert2 from "vue-sweetalert2";
import { locals } from "../../utils/locals";
export default {
        components: {
           Notificaton : headerNotificaton,
           Languages,
        },
  data: () => ({
    drawer: false,
    links: [
      {
        icon: "home",
        text: "Homes",
        route: "/",
      },
      {
        icon: "contacts",
        text: "About",
        route: "/dashboard/about",
      },
    ],
    userdata: {},
    items: {},
    camers: {},
    currentProject: "",
    currentCamere: "",
    fav: true,
    menu: false,
    message: false,
    hints: true,
    activelink: "/dashboard",
    activecolor: "amber accent-3",
    dialogm1: "",
    current_cam: "",
    manutoggle: false,
    useraccess: false,
    useraccesssuperadmin: false,
    nofreeaccess: false,
    camid: 0,
    selectedItem: 0,
    logop: logo,
    rawadhalogo: rawadhalogo,
    lisuallogo: lisuallogo,
    tatalogo:tatalogo,
    lntlogo:lntlogo,
    smlogo : smlogo,
    lodhalogo: lodhalogo,
    adanilogo:adanilogo,
    globaltimelapselogo:globaltimelapselogo,
    equalbaselogo: equalbaselogo,
    tbox : tbox,
    protbox:protbox,
    logo : '',
    project_image: project_image,
    searchtext: "",
    projectSearchdata: {},
    searchloader: false,
    apdata: "",
    acdata: "",
    ap: "",
    ac: "",
    i: 0,
    pname: "",
    billingaccess: false,
    procoreshare: true,
    showurls: true,
    procer_project: "",
    procore_url: "/dashboard/settings/integration/procore_connection",
    isActive: false,
    user_material_access: false,
    user_interior_access: false,
    user_linear_access : false,
    linear_edit_access : false,
    user_drone_access: false,
    user_cont_camera_aceess: false,
    checkcam360: false,
    archive: true,
    ai: false,
    aqi: false,
    people_count: false,
    zoho_id: false,
    support_popup: false,
    support_subject: "",
    supportmsg: "",
    sup_project: "",
    sup_org: "",
    sup_city: "",
    sup_camid: "",
    sup_camidd: "",
    sup_send: false,
    valid: true,
    fullusername:'',
    useremailadd:'',
    usermobile:'',
    showmobilemenu : false,
    camconfig_access:false,
    projid : 0,
    camera_name:'',
    report_items:[
      { icon: "", title: "Create Detailed Report", route: "/dashboard/reports/cart-report" },
        {
          icon: '',
          title: 'Instant Report',
         subitems: [
            { title: 'Create Now', route:"/dashboard/reports/instant-report"},
            { title: 'Schedule it', route:"/dashboard/reports/schedule-report"},
           
          ]
        }
      ],
    Local :locals,  
    lang : {}, 
    autodesk_viewer : 0,
    fullpopup : false,
    camerspopup : [],
    cid : 0,
    activeproarray: [],
     ecamaccess: false,
     openAccessFeature:false,
     hasCRMAccess:false,
     hasDailyReportAccess:false,
     projectCity: [],
     popupProjectList: {},
     projectStatus:["All","Ongoing","Completed"],
      selectedCity: null,
      selectedProjectStatus: null,
  }),
  watch: {
    "$store.state.currentCamera": function () {
      this.current_cam = this.$store.state.currentCamera;
      //  console.log("navbar cam data", this.current_cam.pro_cam_serial);
        this.camera_name =  this.current_cam.pro_cam_serial;
      this.activelink = this.$route.path;
      
      this.ProjectName();
      if (this.current_cam.cam_360 == 1) {
       // console.log("navbar Current cam data 360 cam");
        this.checkcam360 = true;
      } else {
        this.checkcam360 = false;
       // console.log("navbar Current cam data not 360 cam");
      }
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.sup_project = currentProject.project_name;
      this.projid = currentProject.id;
      this.sup_org = currentProject.company;
      this.sup_city = currentProject.city;
      this.autodesk_viewer = currentProject.autodesk_viewer;
      this.sup_camidd = this.$store.state.currentCamera.camera_id;
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;
      //console.log("opticvyu user", this.userdata.opticvyu_user);
      if(this.userdata.opticvyu_user == 1){
            this.camconfig_access = true;
      }else{
           this.camconfig_access = false;
      }
       if (this.current_cam.ppe == 1) {
       // console.log("Ai Activet");
        this.ai = true;
      } else {
        this.ai = false;
       
      }
       if (this.current_cam.aqi == 1) {
       // console.log("aqi Activet");
        this.aqi = true;
      } else {
        this.aqi = false;
       
      }

        if (this.current_cam.people_count == 1) {
        //console.log("people_count Activet");
        this.people_count = true;
      } else {
        this.people_count = false;
       
      }
    },
    $route: function (to) {
      //                if (userdata.cont_camera_aceess == 1) {
      //     redirect = '/dashboard';
      //   }else if (userdata.interior_access == 1) {
      //     redirect = '/dashboard/user-interior-view';
      //   }else if (userdata.material_access == 1) {
      //     redirect = '/dashboard/material/inventories';
      //   }else if (userdata.drone_access == 1) {
      //     redirect = '/dashboard/user-drone-view';
      //   }

      //console.log("Router change", to);
      if (
        to.name == "changepassword" ||
        to.name == "adduser" ||
        to.name == "schedulemail" ||
        to.name == "cameranotification" ||
        to.name == "camerainfo" ||
        to.name == "customizetimelapse" ||
        to.name == "bim_rendering"
      ) {
        this.manutoggle = true;
        this.menu = false;
      } else {
        this.manutoggle = false;
      }

      if (
        to.name == "changepassword" ||
        to.name == "adduser" ||
        to.name == "schedulemail" ||
        to.name == "cameranotification" ||
        to.name == "camerainfo" ||
        to.name == "customizetimelapse" ||
        to.name == "bim_rendering" ||
        to.name == "procore_connection" ||
        //to.name == "invoices" ||
        to.name == "global-dashboard" ||
        to.name == "bim_rendering" ||
        to.name == "manageuser" ||
        to.name == "createtimelapse" ||
        to.name == "createadvance" ||
        to.name == "createproject_timelapse" ||
        to.name == "Cam_Name_Tag" ||
        to.name == "two_factor_auth" ||
        to.name == "cam_configuration" ||
        to.name == "CamInstallInfo" 
      ) {
        this.showurls = false;
      } else {
        this.showurls = true;
      }

      if (to.name == "dashboard" || to.name == "dashboard-360") {
        this.archive = true;
        //console.log(" this.archive", this.archive);
      } else {
        this.archive = false;
        //console.log(" this.archive", this.archive);
      }
      this.zoho_id = this.currentProject.zoho_id == "" ? false : true;

      //console.log("this.zoho_id", this.zoho_id);
      this.userlogs();  
    },
    "$store.state.currentModule": function () {
      var currentModule = this.$store.state.currentModule;
     console.log("currentModule", currentModule);
    },
  },
  computed: {
    queryparams() {
      return {
        to: "admin@opticvyu.com",
        message: this.supportmsg,
        subject: this.support_subject,
        //attachment: this.source[0].url,
        project: this.sup_project,
        org: this.sup_org,
        city: this.sup_city,
        camera_id: this.sup_camid + " (" + this.sup_camidd + ")",
        username:this.fullusername,
        useremail:this.useremailadd,
        usercontact:this.usermobile,
      };
    },

      size() {
      const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
        return size ? { [size]: true } : {}
    }
    //isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  created() {

    var chenal_partner = window.location.hostname;
        if(chenal_partner == "tpl.opticvyu.com"){
          this.drawer = false;
          console.log("checking");
          this.fullpopup = false;
        }else{
            var fullpopup =  localStorage.getItem('ppopup');
            // console.log("fullpopup", fullpopup);
            if(fullpopup  == 'true'){
            this.drawer = true;
            this.fullpopup = true;
            }
        }
     
    if (this.$route.name == "global-dashboard") {
      this.showurls = false;
      this.archive = false;
    } else {
      this.showurls = true;
      this.archive = true;
    }
    if (this.$route.name == "dashboard" || this.$route.name == "dashboard-360") {
      this.archive = true;
    } else {
      this.archive = false;
    }

    this.getPorjects();
    var p = new URL(location.href).searchParams.get("p");

    this.ap = p;

    var c = new URL(location.href).searchParams.get("c");

    this.ac = c;

    var indexOf = new URL(location.href).searchParams.get("i");

    this.i = indexOf;

    //  console.log(">>>>>>>>",this.ap);
    //  console.log(">>>>>>>>",this.i);
    
    this.userdata = JSON.parse(localStorage.getItem("userdata"));

    //console.log(this.userdata);

    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    if (this.userdata.user_code == 2) {
      this.useraccesssuperadmin = true;
    }
     if (this.userdata.ecam == 1) {
      this.ecamaccess = true;
    }
    if (this.userdata.billing_access == 1) {
      this.billingaccess = true;
    }
    if (this.userdata.free_access == 0) {
      this.nofreeaccess = true;
    }
    if (this.userdata.interior_access == 1) {
      this.user_interior_access = true;
    }
    if (this.userdata.material_access == 1) {
      this.user_material_access = true;
    }
    if (this.userdata.drone_access == 1) {
      this.user_drone_access = true;
    }
    if (this.userdata.cont_camera_aceess == 1) {
      this.user_cont_camera_aceess = true;
    }
    //console.log("user details", this.userdata);

     if (this.userdata.linear_monitoring_access == 1) {
            this.user_linear_access = true
        }
    if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1)  {
      this.hasCRMAccess = true;
    }
    if (this.userdata.user_code == 2 && this.userdata.opticvyu_user == 1)  {
      this.hasDailyReportAccess = true;
    }

    if (this.user_cont_camera_aceess !== true) {
      // this.$router.push('/')
    }

    if (this.userdata.open_access == 1) {
      this.openAccessFeature = true;
    }
    this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
    this.useremailadd = this.userdata.email;
    this.usermobile = this.userdata.contactNo;
    this.currentRouteName();

    var pop = localStorage.getItem("procer_project");

    //  console.log("pop", pop);
    if (pop > 0) {
      this.procore_url = "/dashboard/settings/integration/procore_update";
    }

    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.drawer = false;
      }
     // console.log("opticvyu user", this.userdata.opticvyu_user);
    });
  },
  mounted() {

     this.lang = locals['English'];
     setTimeout(() => {
           this.initViewer();
      }, 2000);
   
  },
  methods: {
    getCurrentProject(){
       axios({
                    url: "/projec/"+this.projid,
                    method: "get",
                })
                .then((resp) => {
                  //  console.log("project update date", resp.data);
                    localStorage.setItem("currentProject", JSON.stringify(resp.data));
                })
                .catch((err) => {
                    console.log("project update date",err);
                  
                });
    },
    userlogs(){

            var logdata = {
                "user_name" : this.userdata.firstname+' '+this.userdata.lastname,
                "user_id" : this.userdata.id,
                "user_type" : this.userdata.user_code,
                "opticvyu_user" : this.userdata.opticvyu_user,
                "visit_page" : this.$route.meta.title,
                "project_id" : this.projid,
                "project_type" : "Construction Camera",
                "cam_id" : this.sup_camidd,
                "zone_name" : "",
                "drawing_name" : "",
                "project_name" : this.sup_project,
                "device" : 'website'
          }
               axios({
                    url: "/userlog/"+this.projid+"/add_record",
                    data: logdata,
                    method: "post",
                })
                .then((resp) => {
                    console.log("user logs added--------------------->", resp);
                })
                .catch((err) => {
                    console.log("user log error",err);
                  
                });
          },
          
    initViewer() {

           // console.log("user logs this.$route.--------------------->", this.$route.meta.title); 

        this.activelink = this.$route.path;
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.sup_project = currentProject.project_name;
      this.sup_org = currentProject.company;
      this.sup_city = currentProject.city;
      this.autodesk_viewer = currentProject.autodesk_viewer;
      this.sup_camidd = this.$store.state.currentCamera.camera_id;
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.fullusername = this.userdata.firstname+ ' '+this.userdata.lastname;
      this.useremailadd = this.userdata.email;
      this.usermobile = this.userdata.contactNo;
      this.camera_name =  this.$store.state.currentCamera.pro_cam_serial;
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      //console.log("opticvyu user", this.userdata.opticvyu_user);
      if(this.userdata.opticvyu_user == 1){
            this.camconfig_access = true;
      }else{
            this.camconfig_access = false;
      }

     
        var _self =  this;
      setTimeout(() => {
          _self.userlogs()
      }, 5000);
              
              var selectleng = 'English';
        
              var cl = this.$cookies.get('currentLanguage');
           // console.log("currentLanguage home ", cl)
            if (cl === null) {
                this.$cookies.set("currentLanguage", 'English', -1);
                   
            }else{
                this.currentLang = cl;
            }
          selectleng = this.$cookies.get('currentLanguage');

       this.lang = locals[selectleng];

       this.report_items=[
      { icon: "", title: this.lang.CreateDetailedReport, route: "/dashboard/reports/cart-report" },
        {
          icon: '',
          title: this.lang.InstantReport,
         subitems: [
            { title: this.lang.CreateNow, route:"/dashboard/reports/instant-report"},
            { title: this.lang.Scheduleit, route:"/dashboard/reports/schedule-report"},
           
          ]
        }
      ]
    },
    ProjectName() {
      this.pname = this.currentProject.project_name.substr(0, 15);
      this.zoho_id = this.currentProject.zoho_id == "" ? false : true;
      //localStorage.setItem('pc_token', this.currentProject.procore_token);
       var chenal_partner =   this.currentProject.channel_partner;
       if(chenal_partner == "rawadh"){
           this.logo =  this.rawadhalogo;
       }else if(chenal_partner == "lisual"){
        this.logo =  this.lisuallogo;
       }else if(chenal_partner == "tata"){
        this.logo =  this.tatalogo;
       }else if(chenal_partner == "lnt"){
        this.logo =  this.lntlogo;
       }else if(chenal_partner == "spacematrix"){
        this.logo =  this.smlogo;
       }else if(chenal_partner == "lodha"){
        this.logo =  this.lodhalogo;
       }else if(chenal_partner == "opticvyu"){
        this.logo =  this.logop;
       }else if(chenal_partner == "adani"){
        this.logo =  this.adanilogo;
      }else if(chenal_partner == "globaltimelapse"){
        this.logo =  this.globaltimelapselogo;
       }else if(chenal_partner == "tbox_shot_singapore"){
        this.logo =  this.tbox;
       }else if(chenal_partner == "tbox"){
        this.logo =  this.protbox;
       } else if (chenal_partner == "equalbase") {
        this.logo = this.equalbaselogo;
        this.lgclas = "equalbase";
      } else{
        this.logo =  this.logop;
       }
      if (this.currentProject.procore_proj_id) {
        this.procoreshare = false;
      }
    },
    setrouter: function (url) {

       this.showmobilemenu = false;
      if (this.$route.path !== url) {
        this.$router.push(url);
        this.drawer = false;
        //console.log("current router ", this.$route.path);
        this.activelink = this.$route.path;
      }

      //console.log(url);
    },
    conditionsetrouter: function(url){
          this.showmobilemenu = false;
      if (this.$route.path !== url) {
        if(this.useraccess){
            this.$router.push(url);
            this.drawer = false;
            //console.log("current router ", this.$route.path);
            this.activelink = this.$route.path;
        }else{
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to create the custom time-lapse videos. Please contact your superadmin to get the admin privileges",
            showConfirmButton: true,
          });
        }
      }
    },
    checkUserAccess: function(url){
          this.showmobilemenu = false;
      if (this.$route.path !== url) {
        if(this.userdata.open_access == 1){
          this.$swal({
            icon: "error",
            title: "Error",
            html: "You do not have the permissions to view this page. Please contact your superadmin",
            showConfirmButton: true,
          });
        }else{
            this.$router.push(url);
            this.drawer = false;
            this.activelink = this.$route.path;
        }
      }
    },
    logout: function () {
      //console.log("logout butten pressed");
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    getPorjects: function () {
      this.activeproarray = [];
       var _self  = this;
      axios({
        url: "/project",
        method: "GET",
      })
        .then((resp) => {
          //this.userdata = JSON.parse(localStorage.getItem('userdata'))
          // console.log("userdata=>>>", this.userdata);
          localStorage.setItem("procer_project", 0);

          this.items = resp.data.success.filter(function (item) {
            // if (item.procore_status == 1) {
            //  // localStorage.setItem("procer_project", item.id);
            // }

             if(item.project_active == 1){
               _self.activeproarray.push(item.id)
             }

            return item.project_active == 1;
          });
          this.popupProjectList = this.items;

           localStorage.setItem("activeProject", this.activeproarray.toString())

         // console.log("current Projectssss", this.activeproarray);

          this.projectSearchdata = resp.data.success.filter(function (item) {
            return item.project_active == 1
          });

          // Extract city names and store them in projectCity array
          const citySet = new Set();
          resp.data.success.forEach(function (item) {
            if (item.city) {
              citySet.add(item.city);
            }
          });
          this.projectCity = Array.from(citySet).sort();

          if (this.ap) {
            var v = this;
            this.apdata = resp.data.success.filter(function (item) {
              return item.id == v.ap;
            });

            // console.log(" this.apdata ",  this.apdata );

            this.initialSetup(this.apdata[0]);

            this.ProjectName();
          } else {
            if (this.items.length > 0) {
              let currentProject = JSON.parse(
                localStorage.getItem("currentProject")
              );
              //console.log("currentProject",currentProject);
              if (currentProject == null) {
                this.initialSetup(this.items[0]);

                this.ProjectName();
              } else {
                // console.log("Project Already Set")
                this.initialSetup(currentProject);
                this.ProjectName();
              }
            }
          }
          console.log("items.length", this.items.length);
          if(this.items.length == 1){
            this.drawer = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterProjects() {
      var selectedProjectStatusIntVal = null;
      if (this.selectedProjectStatus == "Ongoing") {
        selectedProjectStatusIntVal = 0;
      }else if(this.selectedProjectStatus == "Completed"){
        selectedProjectStatusIntVal = 1;
      }else{
        selectedProjectStatusIntVal = null;
      }
      
      if (selectedProjectStatusIntVal == null && this.selectedCity == null) {
        this.popupProjectList = this.items;
      }else if(selectedProjectStatusIntVal == null && this.selectedCity != null){
      this.popupProjectList = this.items.filter(item => item.city === this.selectedCity);
      }else if (selectedProjectStatusIntVal != null && this.selectedCity == null) {
        this.popupProjectList = this.items.filter(item =>item.service_discontinue === selectedProjectStatusIntVal);
      }else{
        this.popupProjectList = this.items.filter(item => item.city === this.selectedCity && item.service_discontinue === selectedProjectStatusIntVal);
      }
    },
    filterObjects() {

      
      var filter = this.searchtext.toLowerCase();
      var filtered = {};
      this.searchloader = true;
      /// console.log(projectSearchdata);
      var keys = Object.keys(this.projectSearchdata);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        var object = this.projectSearchdata[key];
        
        var objectAsString = JSON.stringify(object).toLowerCase();
        //   console.log(objectAsString);
        if (
          
          key.toLowerCase().indexOf(filter) > -1 ||
          objectAsString.indexOf(filter) > -1
        ) {
          filtered[key] = object;
        }
      }

      // console.log(filtered);
      // this.items = filtered;
      // this.searchloader = false;
      // if (filter == "") {
      //   this.items = filtered;
      // }
      this.popupProjectList = filtered;
      this.searchloader = false;
      if (filter == "") {
        this.popupProjectList = filtered;
      }
    },

    selectConstruction: function (projectdata,index) {
        localStorage.setItem("projectIndex", index);
      if (this.ap) {
        window.location.href = "/dashboard";
      }

      if (
        this.$store.state.currentModule == "material" ||
        this.$store.state.currentModule == "interior" ||
        this.$store.state.currentModule == "drone"
      ) {
        this.$store.dispatch("curentModule", "construction_camera");
        window.location.href = "/dashboard";
      }

     // console.log("curentModule", this.$store.state.currentModule);

      localStorage.setItem("currentCamIndex", 0);
      this.camid = 0;
      localStorage.setItem("currentProject", JSON.stringify(projectdata));

      this.getCurrentProjectData("construction_camera");
    },
    selectConstructionwithCame: function (projectdata, camid, index, cindex, movetype ) {
          //console.log("camid",camid, index);
          this.ac = camid;
           this.i = cindex;
        localStorage.setItem("projectIndex", index);
       if(this.ap) {
          window.location.href = "/dashboard";
        }

      if(
        this.$store.state.currentModule == "material" ||
        this.$store.state.currentModule == "interior" ||
        this.$store.state.currentModule == "drone"
      ) {
        this.$store.dispatch("curentModule", "construction_camera");
        window.location.href = "/dashboard";
      }

     // console.log("curentModule", this.$store.state.currentModule);

      //localStorage.setItem("currentCamIndex", index);
     
      localStorage.setItem("currentProject", JSON.stringify(projectdata));

      this.getCurrentProjectData(movetype);
    },
    selectMaterial: function (projectdata) {
      localStorage.setItem("currentProject", JSON.stringify(projectdata));

      if (
        this.$store.state.currentModule == "construction_camera" ||
        this.$store.state.currentModule == "interior" ||
        this.$store.state.currentModule == "drone"
      ) {
        this.$store.dispatch("curentModule", "material");
        window.location.href = "/dashboard/material/inventories";
      }
     // console.log("curentModule", this.$store.state.currentModule);

      this.getCurrentProjectData("material");
      this.setrouter("/dashboard/material/inventories");
    },
    selectDrone: function (projectdata) {
      localStorage.setItem("currentProject", JSON.stringify(projectdata));
      if (
        this.$store.state.currentModule == "construction_camera" ||
        this.$store.state.currentModule == "material" ||
        this.$store.state.currentModule == "interior"
      ) {
        this.$store.dispatch("curentModule", "drone");
        window.location.href = "/dashboard/user-drone-view";
      }
     // console.log("curentModule", this.$store.state.currentModule);
      this.$store.dispatch("curentModule", "drone");
      this.getCurrentProjectData("drone");
      this.setrouter("/dashboard/user-drone-view");
    },

    selectInterior: function (projectdata) {
      localStorage.setItem("currentProject", JSON.stringify(projectdata));
      if (
        this.$store.state.currentModule == "construction_camera" ||
        this.$store.state.currentModule == "material" ||
        this.$store.state.currentModule == "drone"
      ) {
        this.$store.dispatch("curentModule", "interior");
        if (this.userdata.open_access == 1) {
          window.location.href = "/dashboard/user-interior-view-open-access";
        }else{
          window.location.href = "/dashboard/user-interior-view";
        }
      }
     // console.log("curentModule", this.$store.state.currentModule);
      this.$store.dispatch("curentModule", "interior");
      this.getCurrentProjectData("interior");
      if (this.userdata.open_access == 1) {
        this.setrouter("/dashboard/user-interior-view-open-access");
      }else{
      this.setrouter("/dashboard/user-interior-view");
      }
    },
   
     selectLinear : function (projectdata) {

            localStorage.setItem('currentProject', JSON.stringify(projectdata))
             if(this.$store.state.currentModule == "construction_camera" || this.$store.state.currentModule == "material" || this.$store.state.currentModule == "drone") {
                  this.$store.dispatch('curentModule','geo')
                window.location.href = "/dashboard/gps-map-view"
            }
           // console.log('curentModule',this.$store.state.currentModule);
             this.$store.dispatch('curentModule','geo')
            this.getCurrentProjectData('geo')
            this.setrouter('/dashboard/gps-map-view');
 
        }, 
     
        // cameras view on image hover

        getCurrentProjectCams(id) {
                   
              
            if(id != this.cid){

          this.cid = id;
            
        //  console.log("mouse over",id);
           
      //console.log(this.currentProject);
     
      this.camerspopup = [];
      axios({
        url: "/camera/" + id,
        method: "GET",
      })
        .then((resp) => {

           var a = 0;
          var b = 1;
          this.camerspopup = resp.data.success.filter(function (item) {
       
            return item.camera_active == 1;
          }); 
          
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
            }
    },
 

    getCurrentProjectData(init) {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.currentProject = currentProject;
      // console.log(this.currentProject);
    

       var chenal_partner = window.location.hostname;
        if(chenal_partner == "tpl.opticvyu.com"){
          this.drawer = false;
          console.log("checking");
          this.fullpopup = false;
        }else{
          if(init == 'init'){
       this.drawer = true;
       this.fullpopup = true;
       }else{
         this.drawer = false;
         this.fullpopup = false;
         localStorage.setItem('ppopup', false);
       }
        }

       
      this.camers = {};
      axios({
        url: "/camera/" + currentProject.id,
        method: "GET",
      })
        .then((resp) => {
          var currentcam = localStorage.getItem("currentCamIndex");
          this.preloader = false;
          var a = 0;
          var b = 1;
          
         

          this.camers = resp.data.success.filter(function (item) {
            if (item.cam_name == "ov") {
            item.cam_name = "";
          } else {
            console.log("camname defined", item.cam_name);
          }
            item.camName = item.pro_cam_serial + " " + item.cam_name;
            item.camNames = "CAM " + b + " " + item.cam_name;
            //this.sup_camid = "CAM " + b + " " + item.cam_name;
            item.iindex = a;
            a++;
            b++;
            return item.camera_active == 1;
          }); 

          //console.log("Cameras", this.camers);

          if (this.ac) {

             // console.log("this.ac",this.ac);
            var v = this;
            // var x = 0;
            this.acdata = this.camers.filter(function (item) {
              return item.camera_id == v.ac;
            });
            localStorage.setItem("cid", this.acdata[0].camera_id);
            this.setCamera(this.acdata[0]);
            localStorage.setItem("currentCamIndex", this.i);
            this.camid = parseInt(this.i);
            // console.log("this.acdata ",this.acdata, x);
          } else {
            if (currentcam === null) {
              //  console.log("in if");
              localStorage.setItem("cid", this.camers[0].camera_id);
              this.setCamera(this.camers[0]);

              localStorage.setItem("currentCamIndex", 0);
              this.camid = 0;
            } else {
              // console.log("currentcam",currentcam);
              localStorage.setItem("cid", this.camers[currentcam].camera_id);
              localStorage.setItem("currentCamIndex", currentcam);
              this.setCamera(this.camers[currentcam]);

              this.camid = parseInt(currentcam);

              //   console.log("in else > ", this.camers );

              // this.preloader = false;

              if (currentcam === "undefined") {
                //   console.log("in else >>>> ", this.camers[currentcam]);
                localStorage.setItem("cid", this.camers[0].camera_id);
                this.setCamera(this.camers[0]);

                localStorage.setItem("currentCamIndex", 0);
                this.camid = 0;
              }
            }
          }
           if(init == "all"){
             this.setrouter('/dashboard/splitscreen');
           }
          //console.log("This is current camera", this.camers[0]);
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    selectCamera(event) {
      //console.log(event);

      localStorage.setItem("currentCamIndex", event);
      // let c =  this.camers[event.target.value]
      // console.log("select camera data ", this.camersevent);
      this.setCamera(this.camers[event]);
      //  this.camid(event)
    },
    initialSetup(initialidata) {
      //  console.log("initialidata", initialidata);
      localStorage.setItem("currentProject", JSON.stringify(initialidata));
      this.getCurrentProjectData('init');
    },
    setCamera(data) {
      this.$store
        .dispatch("setCamera", data)
        .then(() => console.log("camera set data"))
        .catch((err) => console.log(err));
    },
    currentRouteName() {
      // console.log("current router ", this.$route.path);
      this.activelink = this.$route.path;
    },
    support: function () {
      window.open("https://www.opticvyu.com/contact-us", "_blank");
    },
    clearSearch() {
      this.searchtext = "";
      // this.items = this.projectSearchdata;
      this.popupProjectList = this.projectSearchdata;
    },

    closeDrower() {
      this.drawer = false;
      this.fullpopup = false;
      localStorage.setItem('ppopup', false);
    },
    archiveOpen() {
      this.$store.dispatch("openarchive", true);
    },
    support_dialog() {
      this.support_popup = true;
      // this.params.attachment = this.source[0].url;
    },
    async submitquery() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.support_query(this.queryparams); // action to login
      }
    },
    support_query(sharedata) {
      //  console.log("data", sharedata);
      this.sup_send = true;

      axios({
        url: "https://secure.opticvyu.com/api/send-support-request",
        data: sharedata,
        method: "post",
      })
        .then((resp) => {
          console.log(resp);
          this.support_popup = false;
          this.sup_send = false;
        })
        .catch((err) => {
          console.log(err);
          this.sup_send = false;
          this.snackbar = true;
          this.text = `Email delivery faild.`;
          this.preloader = false;
        });
    },
    Authentication: function () {
                  window.open(
            'https://www.opticvyu.com/',
            '_blank'
          );
      
      },
     mobilemenus(){
       this.showmobilemenu = !this.showmobilemenu;
     } 
  },
};
</script>

<style>
.popcamitem .v-icon, .popcamitem span{
  color: #000 !important;
  
}

.popcamitem .v-icon:hover, .popcamitem span:hover{
  color: #ffc400 !important;
  
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 42px !important;
}

.v-list.v-sheet.theme--light {
  padding: 0;
}
.popcamitem{
  margin: 0 2px;
  cursor: pointer;
  height:35px;
  width:35px;
  line-height:30px;
  text-align:center;
  border: 1px solid #ccc;
  border-radius: 50%;
  background:#fff;
  display:inline-block;
  transition:0.5s;
}
.popcamitem:hover{
  box-shadow: 0 0 20px 0px #ffc400 ;
  border: 1px solid #ffc400 ;
  background:#000;
  transition:0.5s;
}

.popcamitem:hover .v-icon{
  color: #ffc400 !important;
}

.popcamitem:hover span{
  color: #ffc400 !important;
}

.projectname {
  font-size: 18px !important;
  padding-left: 16px;
  top: 2px;
  position: relative;
}

.cameraselect {
  max-width: 150px;
  top: 1px;
  left: 21px;
  display: inline-block;
  padding: 0px 8px;
  position: relative;
  color: #929292;
  font-weight: 300;
}

.theme--light.v-sheet {
  background: #fff;
}

.camdropdown {
  top: 15px;
  left: 15px;
  max-width: 113px;
}

.camdropdown .v-input__slot {
  min-height: 30px !important;
}

span.font-weight-bold.shado {
  text-shadow: -2px 4px 4px black;
}

.logo {
  width: 35px;
  position: relative;
  top: 3px;
}

.logolink {
  cursor: pointer;
}

.navmenu_item .v-icon--left {
  margin-left: 0px;
  margin-right: 1px;
}
span.font-weight-bold.shado.logopopup {
  float: left;
  background: #000;
  border-radius: 4px;
}
.navmenu_item {
  padding: 0 3px !important;
}

.mobilemenu  .v-btn__content .v-icon--left {
    margin-left: 2px !important;
    margin-right: 1px !important;
}

.clier {
  position: absolute !important;
  top: 71px;
  right: 25px;
  /* display: block; */
  /* margin-top: 3px; */
}
.searchbox .v-text-field__details,
.searchboxfull .v-text-field__details {
  display: none;
}
.searchboxfull {
    /* width: calc(100% - 236px); */
    /* width: calc(90% - 236px); */
    width: calc(72% - 290px);
    float: left;
    margin: 1px 9px;
    height: 38px;
    box-shadow: 0px 1px 0px #b1aaaa
}
.searchboxfull:hover, .searchboxfull:focus-visible{
  box-shadow: 0px 1px 0px #50a176;
  border: 0;
  outline: 0;
}
.popupnavebar {
  position: fixed;
  top: 55px;
  left: 5%;
  width: 90%;
  height: 90vh;
  background: #fff8f8;
  box-shadow: 0px 0px 9px -2px #080808;
  z-index: 100;
  padding: 25px;
  overflow: auto;
}
.menuprojectname {
  background: #00000069;
  color: #ffffff;
  text-align: center;
  font-weight: bolder;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 4px 0;
}
.hovercms {
    background: #00000069;
    text-align: center;
    position: absolute;
    top: 12px;
    width: 100%;
    padding: 4px 0;
    height: 40px;
    transition: 0.5s;
    opacity: 0;
    z-index: 999999999;
    width: calc(100% - 25px);
}
.projectitem:hover .hovercms {
   
    transition: 0.5s;
    opacity: 1;
}
img.project_thumbnail {
  width: 100%;
}
.projectitem {
  text-align: center;
  position: relative;
}

.proimagebox {
  position: relative;
  display: block;
  height: 152px;
  overflow: hidden;
  background: #ccc;
  margin-bottom: 7px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #ff000047 !important;
  color: #000 !important;
}
.proimagebox.disable {
    pointer-events: none;
}
.proimagebox.enable {
   cursor:pointer;
}
.v-btn.v-size--small {
    font-size: 10px;
}
.logopopup.tata {
    background: #fff !important;
}
@media screen and (max-width: 1200px) {
  .v-toolbar__content .v-size--small {
    padding: 0 5px !important;
  }

  .v-toolbar__content .v-btn.v-size--small {
    font-size: 0.7rem;
  }
 
}
.newproj_exp{
  z-index: 1000;
  background: #c7c7c7;
  position: fixed;
  top: 2%;
  left: 1%;
}
.alerts-border {
    border: 2px #292929 solid;
    height: 40px !important;
    width: 42px !important;
    animation: blink 1s;
    animation-iteration-count: 10;
}
.slideshow{
padding: 6px !important;
}

@keyframes blink { 50% { border-color:#ffff ; }  }
.citySelect{
  width: 10%;
}

.searchBarRow{
  padding-top: 12px;
  padding-right: 34px;
}

@media (max-width: 502px) {
.searchBarRow{
  padding-top: 12px;
  padding-right: 34px;
}

.searchboxfull {
  width: calc(90% - 147px) !important;
  float: left;
  margin: 3px 12px;
}
}
.selectScroll::-webkit-scrollbar {
        width: 10px;
    }

    .selectScroll::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
    }

    .selectScroll::-webkit-scrollbar-thumb {
        background-color: #ffc403;
    }

    .selectScroll::-webkit-scrollbar-thumb:hover {
        background-color: #ffc403;
    }
</style>
